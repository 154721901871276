fetchEvents();

function fetchEvents() {
    var xhr = new XMLHttpRequest();
    xhr.open("GET", "https://api.cfparts.ch/api/board/events?password=9ucWn2dxjaL&from=1719850140", true);
    xhr.onreadystatechange = function () {
        if (xhr.readyState === 4 && xhr.status === 200) {
            let eventsResponse = JSON.parse(xhr.responseText);
            window.events = eventsResponse.data;
            displayEvents(eventsResponse.data);
        }
    };
    xhr.send();
}

window.displayEvents = function (events) {
    var eventsContainer = document.getElementById("EventsTableBody");
    eventsContainer.innerHTML = "";

    let allEvents = [];
    events.forEach(function (event) {
        if (eventSameDay(event)) {
            allEvents.push(event);
        } else {
            let eventTo = Object.assign({}, event);
            event.isDuplicateTo = false;
            eventTo.isDuplicateTo = true;
            allEvents.push(event);
            allEvents.push(eventTo);
        }
    });

    let groupedEvents = {};
    allEvents.forEach(function (event) {
        let rawDate = event.isDuplicateTo ? event.to : event.from;
        let date = new Date(rawDate * 1000);
        let month = date.getMonth();
        let year = date.getFullYear();
        if (!groupedEvents[month + "" + year]) {
            groupedEvents[[month + "" + year]] = { month: date, events: [] };
        }
        groupedEvents[[month + "" + year]].events.push(event);
    });

    groupedEvents = Object.values(groupedEvents);

    groupedEvents = groupedEvents.sort(function (lhs, rhs) {
        let lhsDate = lhs.month;
        let rhsDate = rhs.month;
        return lhsDate - rhsDate;
    });

    groupedEvents.forEach(function (group) {
        let monthElement = createMonthHeaderElement(group.month);
        eventsContainer.appendChild(monthElement);

        let sortedEvents = group.events.sort(function (lhs, rhs) {
            let lhsDate = lhs.isDuplicateTo ? lhs.to : lhs.from;
            let rhsDate = rhs.isDuplicateTo ? rhs.to : rhs.from;
            return lhsDate - rhsDate;
        });

        sortedEvents.forEach(function (event) {
            let eventElement = createEventElement(event);

            eventsContainer.appendChild(eventElement);
        });
    });

    let appTitle = document.getElementById("AppTitle");
    appTitle.innerHTML = "Calendrier ";
    if (groupedEvents.length > 1) {
        let beginYear = groupedEvents[0].month.toLocaleDateString(undefined, { year: 'numeric' });
        let endYear = groupedEvents[groupedEvents.length - 1].month.toLocaleDateString(undefined, { year: 'numeric' });
        appTitle.innerHTML += beginYear;
        if (beginYear !== endYear) {
            appTitle.innerHTML += " - " + endYear;
        }
    }
}

function createMonthHeaderElement(month) {
    var monthElement = document.createElement("tr");
    let container = document.createElement("td");
    container.colSpan = 5;
    container.className = "monthHeaderContainer";

    let title = document.createElement("div");
    title.className = "monthHeader bodyText";
    title.innerHTML = month.toLocaleDateString(undefined, { month: 'long' }) + " " + month.getFullYear();

    container.appendChild(title);
    monthElement.appendChild(container);
    return monthElement;
}

function createEventElement(event) {
    var eventElement = document.createElement("tr");
    eventElement.className = "eventRow";
    eventElement.addEventListener("click", onClickOnEvent);
    eventElement.dataset.event = JSON.stringify(event);

    let dateFrom = new Date(event.from * 1000);
    let dateTo = new Date(event.to * 1000);

    let eventDateColumn = document.createElement("td");
    eventDateColumn.style = "min-width: 96pt";

    let hour = document.createElement("td");
    if (eventSameDay(event)) {
        eventDateColumn.innerHTML = formatDateToDay(dateFrom);
        hour.innerHTML = formatDateToHour(dateFrom) + " → " + formatDateToHour(dateTo);
    } else {
        if (event.isDuplicateTo) {
            eventDateColumn.innerHTML = formatDateToDay(dateTo);
            hour.innerHTML = "Fin → " + dateTo.toLocaleTimeString(undefined, { hour: '2-digit', minute: '2-digit' });
        } else {
            eventDateColumn.innerHTML = formatDateToDay(dateFrom);
            hour.innerHTML = "Début → " + dateFrom.toLocaleTimeString(undefined, { hour: '2-digit', minute: '2-digit' });
        }
    }
    eventElement.appendChild(eventDateColumn);
    eventElement.appendChild(hour);

    let title = document.createElement("td");
    title.innerHTML = event.title;
    title.className = "eventTitle";
    eventElement.appendChild(title);

    let places = document.createElement("td");
    let placesContainer = document.createElement("div");
    placesContainer.className = "chipsContainer";
    event.places.forEach(function (place) {
        const placeElement = createPlaceElement(place);
        placesContainer.appendChild(placeElement);
    });
    places.appendChild(placesContainer);
    eventElement.appendChild(places);

    let groups = document.createElement("td");
    let groupsContainer = document.createElement("div");
    groupsContainer.className = "chipsContainer";
    groupsContainer.style = "max-width: 32rem";
    event.groups.forEach(function (group) {
        const groupChip = createGroupChipElement(group);
        groupsContainer.appendChild(groupChip);
    });
    groups.appendChild(groupsContainer);
    eventElement.appendChild(groups);

    let plus = document.createElement("td");
    plus.innerHTML = "+";
    plus.className = "plusButton";
    eventElement.appendChild(plus);

    return eventElement;
}

window.createGroupChipElement = function (group) {
    let groupChip = document.createElement("span");
    groupChip.className = "chip";
    groupChip.innerHTML = group.name;
    return groupChip;
}

window.createPlaceElement = function (place) {
    let placeElement = document.createElement("span");
    placeElement.className = "placeChip bodyText";
    placeElement.innerHTML = place.name;
    return placeElement;
}

window.createBulletElement = function () {
    let bulletElement = document.createElement("span");
    bulletElement.className = "placeChip bodyText";
    bulletElement.innerHTML = "•";
    return bulletElement;
}

window.eventSameDay = function (event) {
    let lhsDate = new Date(event.from * 1000);
    let rhsDate = new Date(event.to * 1000);
    return lhsDate.getDate() === rhsDate.getDate() && lhsDate.getMonth() === rhsDate.getMonth() && lhsDate.getFullYear() === rhsDate.getFullYear();
}

window.formatDateToDay = function (date) {
    let weekDays = ["DI", "LU", "MA", "ME", "JE", "VE", "SA"]
    let day = date.getDay();

    return weekDays[day] + " " + date.toLocaleDateString(undefined, { day: '2-digit' }) + "." + date.toLocaleDateString(undefined, { month: '2-digit' }) + "." + date.toLocaleDateString(undefined, { year: '2-digit' })
}

window.formatDateToHour = function (date) {
    return date.toLocaleTimeString(undefined, { hour: '2-digit', minute: '2-digit' })
}